import s from "./index.module.scss";
import { default as cx } from "clsx";
import * as React from "react";

class Loading extends React.Component<{ loading: boolean }> {
  public render() {
    if (!this.props.loading) return <></>;
    return (
      <div className={s.mask}>
        <div className={s.loading}>
          <div className={cx(s.loading__circle, s.loading__circle__bounce1)}/>
          <div className={cx(s.loading__circle, s.loading__circle__bounce2)}/>
          <div className={s.loading__circle}/>
        </div>
      </div>
    );
  }
}

export default Loading;
