export enum PartName {
  /// 脑
  brain = "brain",
  /// 心脏
  heart = "heart",
  /// 动脉
  heart_artery = "heart_artery",
  /// 肠
  intestinal = "intestinal",
  /// 肾
  kidney = "kidney",
  /// 肝
  liver = "liver",
  /// 气管
  lung_trachea = "lung_trachea",
  /// 肺
  lung = "lung",
  /// 淋巴
  lymph = "lymph",
  /// 脾
  spleen = "spleen",
  /// 胃
  stomach = "stomach",
  /// 脊柱
  spine = "spine",
  /// 身体
  body = "body",
}

export const PartSortOrder = [
  PartName.brain,
  PartName.lymph,
  PartName.heart,
  PartName.lung,
  PartName.spleen,
  PartName.liver,
  PartName.stomach,
  PartName.kidney,
  PartName.intestinal,
];

export const getPartSortOrder = (pn: PartName) => {
  const index = PartSortOrder.indexOf(pn);
  return index < 0 ? 100 : index;
};