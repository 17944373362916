import { ComputedProblem } from "../components/questionnaire";
import { getPartSortOrder, PartName } from "../types/person_parts";
import isMobile, { isMobileResult } from "ismobilejs";
import { C, PLATFORM, REPORT_TYPES } from "./constants";
import { DateTimeFormatOptions, HourCycle } from "luxon";
import store from "store";
// eslint-disable-next-line no-restricted-imports
import { useTranslations as originUseTranslations } from "next-intl";
import { i18n } from "../next-i18next.config";

const formatZero = (num, len) => {
  if (String(num).length > len) return String(num).substr(0, len);
  return (Array(len).join("0") + num).slice(-len);
};

const formatDate = (date: Date) => `${date.getMonth()}/${date.getDate()}`;

const formatTime = (time: Date, milliseconds = true) => `${formatZero(time.getMinutes(), 2)}:${formatZero(time.getSeconds(), 2)}` + (milliseconds ? `.${formatZero(time.getMilliseconds(), 3)}` : "");

const formatDateAndTime = (d: Date) => `${d.getFullYear()}/${formatZero(d.getMonth(), 2)}/${formatZero(d.getDay(), 2)} ${formatZero(d.getMinutes(), 2)}:${formatZero(d.getSeconds(), 2)}`;

const getColorByScore = (score: number) => {
  if (score < 70) return "red";
  else if (score < 80) return "orange";
  else if (score < 90) return "yellow";
  return "green";
};

export interface alert {
  open: boolean;
  severity?: "error" | "warning" | "info" | "success";
  msg?: string;
  onClose?: Function;
}

const openAlert = (alert: alert) => {
  ((global || window) as any).openAlert(alert);
};

export enum FaceFamily {
  origin = "origin",
  new_type = "new_type",
  water_drop = "water_drop",
}

const getFaceIconByLevel = (level?: number, family?: FaceFamily): string => {
  if (!family) {
    family = FaceFamily.origin;
  }

  if (!level) {
    return `/h5_assets/icons/face/${family}/good.png`;
  }

  switch (level) {
    case 1:
      return `/h5_assets/icons/face/${family}/good.png`;
    case 2:
      return `/h5_assets/icons/face/${family}/not_good.png`;
    case 3:
      return `/h5_assets/icons/face/${family}/bad.png`;
    case 4:
      return `/h5_assets/icons/face/${family}/very_bad.png`;
    default:
      return `/h5_assets/icons/face/${family}/good.png`;
  }
};

const getColorByLevel = (level?: number): string => {
  const green = '#74D803';
  const red = '#E30000';
  const yellow = '#FDD418';
  const orange = '#FD6D00';
  
  switch (level) {
    case 4:
      return red;
    case 2:
      return yellow;
    case 3:
      return orange;
    case 1:
    default:
      return green;
  }
};

const SortProblems = (displayCount: number, problems: ComputedProblem[]): ComputedProblem[] => {
  if (displayCount <= 0) return [];
  const sp = problems.sort((a, b) => getPartSortOrder(a.partName) - getPartSortOrder(b.partName));
  const splitIndex = sp.findIndex(p => !p.partName);
  const res = sp.splice(0, splitIndex);

  if (displayCount > res.length && res.length > 0) {
    if (res[0]?.partName && res[0]?.partName != PartName.brain) {
      sp.length && res.unshift(sp.pop());
    }
    if (res[1]?.partName && !res.find(p => [PartName.brain, PartName.lymph, PartName.heart].includes(p.partName))) {
      sp.length && res.splice(1, 0, sp.pop());
    }
  }

  res.push(...sp);
  return res;
};

const getPartNameById = (id: string) => {
  // 內分泌、腺體、睡眠不佳、壓力過高、情緒異常
  if (["A14", "A16", "C4", "C6", "C7"].includes(id)) return PartName.brain;
  // 呼吸道
  if (["A9"].includes(id)) return PartName.lung_trachea;
  // 神經
  if (["A15"].includes(id)) return PartName.spine;
  // 體循環、血液
  if (["A6", "A5"].includes(id)) return PartName.heart_artery;
  // 免疫、感染、過敏
  if (["B1", "B3", "B5"].includes(id)) return PartName.lymph;
  // 心臟、心血管、血壓
  if (["A2", "A4", "A3"].includes(id)) return PartName.heart;
  // 肺臟 、感冒
  if (["A8", "A10"].includes(id)) return PartName.lung;
  // 活動過度、活動量不足、過度疲勞
  if (["C2", "C3", "C5"].includes(id)) return PartName.body;
  // 肝臟、新陳代謝、體內發炎、毒素累積
  if (["A1", "A12", "B4", "B2"].includes(id)) return PartName.liver;
  // 血糖
  if (["A13"].includes(id)) return PartName.spleen;
  // 腸胃
  if (["A7"].includes(id)) return PartName.stomach;
  // 腎臟
  if (["A11"].includes(id)) return PartName.kidney;
  // 營養不佳
  if (["C1"].includes(id)) return PartName.intestinal;
  return null;
};

const isWechat = (win): boolean => {
  const ua = win.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i)?.toString() == "micromessenger";
};

const platformDetector = (): isMobileResult & { isWechat: boolean, isAndroid: boolean, isIOS: boolean, platform: PLATFORM } => {
  let win = (global || window);
  if (win && win.navigator) {
    let result: any = isMobile(win.navigator);
    result.isAndroid = result.android.device;
    result.isIOS = result.apple.device;
    result.isWechat = isWechat(win);
    if (result.isIOS) {
      result.platform = PLATFORM.ios;
    } else if (result.isAndroid) {
      result.platform = PLATFORM.android;
    } else {
      result.platform = PLATFORM.web;
    }
    return result;
  } else return null;
};

const getYinAndYangByType = (type: "yi" | "ya" | null) => {
  switch (type) {
    case "ya":
      return "{sun| }";
    case "yi":
      return "{moon| }";
    default:
      return "";
  }
};

const getReportTypeByShoreName = (shortName: "ia" | "p" | "s" | "r" | "d" | "x"): REPORT_TYPES => {
  switch (shortName) {
    case "ia":
      return REPORT_TYPES.physique;
    case "d":
      return REPORT_TYPES.resistance;
    case "p":
      return REPORT_TYPES.health;
    case "x":
      return REPORT_TYPES.endurance;
    case "r":
      return REPORT_TYPES.repair;
    case "s":
      return REPORT_TYPES.antistress;
    default:
      return null;
  }
};

type _DateTimeFormatOptions = DateTimeFormatOptions & { hourCycle?: HourCycle };

const setTimeFormatForOptions = (options: DateTimeFormatOptions): _DateTimeFormatOptions => {
  const isHour12 = store.get(C.Time_format, 12);
  const opt: _DateTimeFormatOptions = options;
  if (isHour12 == 12) {
    opt.hour12 = true;
  } else {
    if ((window?.Intl as any)?.Locale && "hourCycle" in (window?.Intl as any)?.Locale) {
      opt.hourCycle = "h23";
    } else {
      opt.hour12 = false;
    }
  }

  return opt;
};

const useTranslations = (): ((key: string, values?: any) => string) => {
  const t = originUseTranslations();
  return (key: string, values?: any) => t(key, values) as string;
};

const getLang = (pageProps): string => {
  const { isSSR, cookie } = pageProps;
  return isSSR ? (cookie[C.I18n_language_key] || i18n.defaultLocale) : store.get(C.I18n_language_key, i18n.defaultLocale);
};

const getSystolicColor = (statusCode: number) => {
  return ["#AC0000", "#94D747", "#FCE039", "#FDA228", "#E30000", "#AC0000"][statusCode - 1] || "#AC0000";
};

const getDiastolicColor = (statusCode: number) => {
  return ["#AC0000", "#94D747", "#FDA228", "#E30000", "#AC0000"][statusCode - 1] || "#AC0000";
};

const getBloodPressureDiffColor = (statusCode: number) => {
  return ["#E30000", "#FCE039", "#94D747", "#FCE039", "#E30000"][statusCode - 1] || "#E30000";
};

export {
  formatZero,
  formatDate,
  formatTime,
  formatDateAndTime,
  getColorByScore,
  getColorByLevel,
  openAlert,
  getFaceIconByLevel,
  SortProblems,
  getPartNameById,
  platformDetector,
  getYinAndYangByType,
  getReportTypeByShoreName,
  setTimeFormatForOptions,
  useTranslations,
  getLang,
  getSystolicColor,
  getDiastolicColor,
  getBloodPressureDiffColor,
};