const path = require('path');
const locales = [
  'zh-TW',
  "zh-CN",
];

if (process.env.NODE_ENV === "development") {
  locales.push("en");
}

module.exports = {
  i18n: {
    defaultLocale: 'zh-TW',
    locales: locales,
  },
  localePath: path.resolve('./public/h5_assets/messages'),
  ns: 'common',
  defaultNS: 'common',
};
