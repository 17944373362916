export enum C {
  User_token = "KEY_USER_TOKEN",
  User_ID = "KEY_USER_ID",
  User_type = "KEY_USER_TYPE",
  Screen_padding_top = "KEY_SCREEN_PADDING_TOP",
  Screen_padding_bottom = "KEY_SCREEN_PADDING_BOTTOM",
  Screen_padding_left = "KEY_SCREEN_PADDING_LEFT",
  Screen_padding_right = "KEY_SCREEN_PADDING_RIGHT",
  Time_format = "KEY_TIME_FORMAT",
  Show_report_for_doctor = "KEY_SHOW_REPORT_FOR_DOCTOR",
  Questionnaire_data = "KEY_QUESTIONNAIRE_DATA",
  Confirmed_risks = "KEY_CONFIRMED_RISKS",
  Current_ECG_key = "KEY_CURRENT_ECG_KEY",
  I18n_language_key = "KEY_I18N_LANG",
  Heart_svg_path = "path://M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z",
}

export const IgnoreLoadingRouters = [
  "/echarts",
];

export const apiBaseUrl = "http://twapi.xiinhealthtech.com/api/v2";

export enum API {
  login = "/auth/login",
  health_report = "/health/report",
  health_ecg_report = "/health/ecg-report",
  health_save_confirmed_risks = "/health/confirmed-risks",
  health_report_analysis = "/health/report-analysis",
  health_report_analysis_friend = "/health/report-analysis-friend",
  status = "/auth/status",
  check_update = "/system/check/update",
  row_data = "/health/row-data",
  warning_info = "/health/get-warning-info",
  hrv_report_by_duration = "/health/report-hrv-duration",
  hrv_report_by_days = "/health/report-hrv-time",

  search_user = "/apply/search",
  follow_user = "/apply/store",
  health_friend_invitations = "/apply/list/pending",
  handle_health_friend_invitation = "/apply/update",
  following_health_friends = "/apply/list/following",
  follower_health_friends = "/apply/list/followed",
  delete_health_friend = "/apply/delete",
  health_friend_ecg_report = "/apply/ecg-report",

  blood_glucose_basic_single_day_report = "/blood/sugar/basic/getDaily",
  blood_glucose_basic_long_term_report = "/blood/sugar/basic/getTimeRange",
  blood_glucose_basic_delete = "/blood/sugar/basic/delete",

  blood_glucose_advanced_compare_report = "/blood/sugar/senior/getDaily",
  blood_glucose_advanced_delete = "/blood/sugar/senior/delete",

  temperature_single_day_report = "/temperature/getDaily",
  temperature_long_term_report = "/temperature/getTimeRange",
  temperature_delete = "/temperature/delete",
  
  blood_pressure_basic_single_day_report = "/blood/pressure/basic/getDaily",
  blood_pressure_basic_long_term_report = "/blood/pressure/basic/getTimeRange",
  blood_pressure_basic_delete = "/blood/pressure/basic/delete",

  blood_pressure_advanced_compare_report = "/blood/pressure/senior/getDaily",
  blood_pressure_advanced_delete = "/blood/pressure/senior/delete",
}

export enum PLATFORM {
  web = 0,
  ios = 1,
  android = 2,
}

export enum REPORT_TYPES {
  // 健康強度
  health = "health",
  // 修復指數
  repair = "repair",
  // 壓力指數
  antistress = "antistress",
  // 續航指數
  endurance = "endurance",
  // 抵抗指數
  resistance = "resistance",
  // 體質偏向
  physique = "physique",
}

export enum HRV_REPORT_MODE {
  duration,
  days,
}

export enum HANDLE_HEALTH_FRIEND_INVITATION_OPERATION {
  agree = 1,
  deny = 2,
}

export enum HEALTH_FRIEND_TYPE {
  follower = 1,
  following = 2,
}

export const XIIN_ECG_FILE_HEADER = Int8Array.from("XIINECGD".split("").map(c => c.charCodeAt(0)));

export enum BloodGlucoseRecordBasicType {
  emptyStomach10hour,
  $30minBeforeSleep,
  randomMeasure,
}

export enum BloodGlucoseRecordAdvancedType {
  $2hourAfterEating,
  $30minAfterUseMedicine,
  $30minAfterUseTonic,
  $30minAfterExercise,
}

export enum BloodPressureRecordBasicType {
  $30minAfterGetUp,
  $30minBeforeSleep,
  randomMeasure,
}

export enum BloodPressureRecordAdvancedType {
  $30minAfterEating,
  $30minAfterUseMedicine,
  $30minAfterExercise,
  $30minAfterUseTonic,
}