import "../styles/styles.scss";
import * as React from "react";
import { useEffect, useState } from "react";
import Head from "next/head";
import Loading from "../components/loading";
import { useRouter } from "next/router";
import { C, IgnoreLoadingRouters } from "../lib/constants";
import { createTheme, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { alert, getLang } from "../lib/utils";
import App, { AppProps } from "next/app";
import { NextIntlProvider } from "next-intl";
import { ThemeProvider } from "@material-ui/styles";
import { blue, green } from "@material-ui/core/colors";
import store from "store";
import serverCookies from "next-cookies";
import ClientCookies from "js-cookie";
import Error from "next/error";
import * as Sentry from "@sentry/nextjs";

const twMessages = require(`../public/h5_assets/messages/zh_TW/common.json`);
const cnMessages = require(`../public/h5_assets/messages/zh_CN/common.json`);

// This default export is required in a new `pages/_app.js` file.
function MyApp({ Component, pageProps, err }: AppProps & { err: Error }) {
  let timer = null;
  let interval = null;
  const router = useRouter();

  const ignore = IgnoreLoadingRouters.includes(router.pathname);
  const [loading, setLoading] = useState(!ignore);

  const [alert, openAlert] = useState<alert>({ open: false });

  ((global || window) as any).openAlert = openAlert;

  let handleCloseAlert = () => {
    if (typeof alert.onClose === "function") {
      alert.onClose.apply(this);
    }
    openAlert({ ...alert, open: false });
  };


  useEffect(() => {
    if (window) {
      (window as any).store = store;
      (window as any).setVar = (key, val) => {
        if (store.get(key) != val) {
          store.set(key, val);
          return true;
        }
        return false;
      };
      (window as any).i18n = {
        changeLanguage: (lang: string) => {
          if (!lang) return;
          store.set(C.I18n_language_key, lang);
          ClientCookies.set(C.I18n_language_key, lang);
        },
      };
    }
    if (loading) {
      timer = setTimeout(() => {
        setLoading(false);
        if (interval) {
          clearInterval(interval);
        }
      }, 10000);

      interval = setInterval(() => {
        if (document.readyState == "complete") {
          setLoading(false);
          if (timer) {
            clearTimeout(timer);
          }
          if (interval) {
            clearInterval(interval);
          }
        }
      }, 500);
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: green[500],
      },
      secondary: {
        main: blue[500],
      },
      action: {
        disabledOpacity: 1,
      },
    },
  });

  let viewPortContent = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
  if (router.pathname.startsWith("/h5/")) {
    viewPortContent += ", viewport-fit=cover";
  }

  const paddingTop = store.get(C.Screen_padding_top, 0);

  Sentry.configureScope(scope => {
    scope.setUser({ id: store.get(C.User_ID) });
  });

  return (
    <NextIntlProvider
      messages={getLang(pageProps) === "zh-CN" ? cnMessages : twMessages}
    >
      <Head>
        <meta
          name="viewport"
          content={viewPortContent}
        />
      </Head>
      {
        loading && <Loading loading={loading}/>
      }
      <ThemeProvider theme={theme}>
        <Component {...pageProps} err={err}/>
      </ThemeProvider>
      <Snackbar open={alert.open} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          severity={alert.severity}
          onClose={handleCloseAlert.bind(this)}
          style={{ marginTop: paddingTop }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
    </NextIntlProvider>
  );
}

MyApp.getInitialProps = async (props) => {
  const { ctx } = props;
  const { req } = ctx;
  const appProps = await App.getInitialProps(props);

  const cookie = serverCookies(props.ctx);

  appProps.pageProps = {
    ...appProps.pageProps,
    isSSR: !!req,
    cookie,
  };

  return appProps;
};

export default MyApp;